import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import { useAppData } from './context/DataContext';
import axios from 'axios';

const secrets = require('./secrets.js');
const api = secrets.server_url;

function ForgotPassword(props) {
    const [email, setEmail] = useState('');
    const [isRegistered, setIsRegistered] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [emails, setEmails] = useState([]);
    
    const { users } = useAppData();

    useEffect(() => {
        if (users && Array.isArray(users)) {
            setEmails(users.map(user => user.email));
        } else if (users?.data?.data && Array.isArray(users.data.data)) {
            setEmails(users.data.data.map(user => user.email));
        }
    }, [users]);

    const handleClose = () => {
        props.setTrigger(false);
        // Reset form state when closing
        setEmail('');
        setError('');
        setIsRegistered(true);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (!e.target.value) {
            setIsRegistered(true);
        }
        setError('');
    };

    const handleEmailBlur = () => {
        if (emails.length > 0 && !emails.includes(email)) {
            setIsRegistered(false);
            setError('Email not registered');
        } else {
            setIsRegistered(true);
            setError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!email) {
            setError('Please enter your email address');
            return;
        }

        if (!isRegistered) {
            setError('No account found with this email address');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(`${api}requestPasswordReset`, {
                email: email,
                accountType: props.userType,
                requestType: "Forgot Password"
            }, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                }
            });
            
            message.success('Password reset instructions have been sent to your email');
            handleClose();
            window.location.href = "/";
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred. Please try again.');
            setIsLoading(false);
        }
    };

    return (
        <>
            {props.trigger && (
                <Modal
                title={
                    <div className="sign-title">
                        <p> Forgot Password</p>
                    </div>
                }
                    open={props.trigger}
                    onCancel={handleClose}
                    footer={null}
                    destroyOnClose
                    centered
                >
                    <form onSubmit={handleSubmit}>
                        <div className="inputs">
                            <label htmlFor="email">Email</label>
                            <input
                                required
                                type="email"
                                id="email"
                                name="email"
                                className={`long-input ${!isRegistered ? 'error' : ''}`}
                                value={email}
                                onChange={handleEmailChange}
                                onBlur={handleEmailBlur}
                                disabled={isLoading}
                            />
                            {error && <div className="error-message">{error}</div>}
                        </div>
                        
                        <button 
                            type="submit"
                            className="signin"
                            disabled={isLoading || !isRegistered}
                        >
                            {isLoading ? 'Sending...' : 'Reset Password'}
                        </button>
                    </form>
                </Modal>
            )}
        </>
    );
}

export default ForgotPassword;