import React, { useState, useEffect, useContext, useRef } from 'react';
import { useAppData } from './context/DataContext';
import { getEventLoc, processLocations } from './information.service.js'
import EventCard from './EventCard';
import { Link } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import FilterPanel from './FilterPanel';
import { DatePicker, Space } from 'antd';
import noEventsImage from './NoEvents.jpg';
import { ReactComponent as NoEvents } from './NoEvents.svg';
import OrganizerNavbar from './OrganizerNavbar';

const recurring = ["N/A", "Daily", "Weekly", "Monthly"];
const age = ["N/A", "Youth", "HS/College", "Adult"];
const gender = ["N/A", "Male", "Female"];
const organizer = ["N/A", "Masjid", "Community"];
const price = ["N/A", "Free", "$", "$$", "$$$"];



function createCard(info) {
  
  if(info.event_id == "6852"){
    console.log(info.timeArray)
  }
  return (
    <Link key={info.event_id} to={`/event/${info.event_id}`} className="card-link">
      <EventCard
        imgurl={info.img}
        event={info.name}
        masjid={info.organization}
        date={info.timeArray[0]}

        altdate={info.dateIndex == -1 ? info.dates[0] : info.dates[info.dateIndex]}
        time={info.start_time}

        description={info.description}
        age={info.age_range}
        frequency={info.frequency}
        price={info.price}
        gender={info.gender}
        registered={info.registered}
        
        tags={info.tags}

      />
    </Link>
  );
}

function Events() {

  const {
    information,
    informationWithPastEvents,
    loggedInUser,
    loggedInEmail,
    userInfo,
    userLoc,
    unapprovedEvents,
    selectedOrganization,
    selectedOrganizationInfo,
    managedOrganizations
  } = useAppData();



  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = Number.MAX_SAFE_INTEGER; // Number of events to display per page
  //console.log(eventsPerPage, "max number")
  const [filters, setFilters] = useState({
    organizer: {
      masjid: false,
      community: false
    },
    age: {
      youth: false,
      hsCollege: false,
      adult: false
    },
    gender: {
      male: false,
      female: false
    },
    recurring: {
      daily: false,
      weekly: false,
      monthly: false
    },
    price: {
      free: false,
      paid: false
    },
    date: null,
    distance: null,
    search: "",
    location: ""
  });

  const [filteredInfo, setFilteredInfo] = useState(information);

  useEffect(() => {
    const fetchFilteredInfo = async () => {
      const result = await handleFiltering();
      setFilteredInfo(result);
      if (result.length === 0) {
        setCurrentPage(1);
      }
    };
    fetchFilteredInfo();
  }, [filters, information]);

  async function handleFiltering() {
    const scoredEvents = [];
    const infoToFilter = JSON.parse(JSON.stringify(information));
    // console.log(filters.location)
    if (filters.location) {
      const newUserLoc = await getEventLoc(filters.location);
      await processLocations(infoToFilter, newUserLoc.latitude, newUserLoc.longitude);
      // console.log('PROCESSING LOCS!!!!')
    }
    // console.log("[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[")
    // infoToFilter.forEach(item => {
    //   console.log(item.name)
    //   console.log(item.distanceFromUser)
    //   console.log("----------------")
    // })

    infoToFilter.forEach(item => {
      let isMatch = true;
      let score = 0;

      if (filters.distance && filters.distance !== 0 && !item.location) {
        isMatch = false;
      }

      for (const key in filters) {
        const filterOptions = filters[key];

        if (key === 'location') {
          continue;
        }

        if (key === 'search') {
          if (filterOptions && !(item.name.toLowerCase().includes(filterOptions.toLowerCase()) || item.organization.toLowerCase().includes(filterOptions.toLowerCase()))) {
            isMatch = false;
            break;
          }
          continue;
        }

        if (key === 'date') {
          if (filterOptions) {
            const eventDate = new Date(item.dateIndex === -1 ? item.start_datetime : item.dates[item.dateIndex]);
            const filterDate = new Date(filterOptions);

            if (item.name === "Revival Risalah") {
              // console.log(eventDate)
              // console.log(filterDate)
              // console.log(item.dateIndex)

            }

            filterDate.setHours(0, 0, 0, 0);
            if (eventDate < filterDate) {
              isMatch = false;
              break;
            }
          }
          continue;
        }

        if (key === 'distance') {
          if (filterOptions && item.distanceFromUser > filterOptions) {
            isMatch = false;
            break;
          }
          continue;
        }

        const activeFilters = Object.keys(filterOptions).filter(option => filterOptions[option]);
        if (activeFilters.length > 0) {
          const lowerCaseActiveFilters = activeFilters.map(option => option.toLowerCase());
          const itemValue = (item[key] || "").toLowerCase();

          if (key === 'price') {
            if (filterOptions.paid && filterOptions.free) {
              break;
            } else if (filterOptions.paid && (itemValue === 'free' || itemValue === '0')) {
              isMatch = false;
              break;
            } else if (filterOptions.free && itemValue !== 'free' && itemValue !== '0') {
              isMatch = false;
              break;
            }
          } else if (itemValue === 'all') {
            continue;
          } else if (!lowerCaseActiveFilters.includes(itemValue)) {
            isMatch = false;
            break;
          } else {
            score++;
          }
        }
      }

      if (isMatch) {
        scoredEvents.push({ item, score });
      }
    });

    scoredEvents.sort((a, b) => b.score - a.score);
    return scoredEvents.map(event => event.item);
  }


  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = filteredInfo.slice(indexOfFirstEvent, indexOfLastEvent);

  const handleChange = (date) => {
    //console.log('Selected date:', date);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //console.log("information array", information)

  return (
 
    <div>
         {/* {console.log("this is the information array", information)} */}
      <div className="wrapper">
      {loggedInUser.data === "Enterprise User" ? (
         
         <OrganizerNavbar />
       ) : (
         <Navbar />
       )}
        <div className="filter-calendar">
          <div className="grid-filter">
            <FilterPanel setFilters={setFilters} />
          </div>


          <div className="grid-eventcards">
            <div className="header-pagination">
              <h1 className="event-header">Upcoming Events</h1>
            </div>

            {/* <div className="pagination top-pagination">
                {currentPage > 1 && (
                  <button className="pagbtn prev-btn" onClick={() => paginate(currentPage - 1)}>Previous</button>
                )}
                {currentEvents.length > 0 && currentPage < Math.ceil(filteredInfo.length / eventsPerPage) && (
                  <button className="pagbtn next-btn" onClick={() => paginate(currentPage + 1)}>Next</button>
                )}
              </div> */}

            {currentEvents.map(createCard)}
            {currentEvents.length === 0 && (
              <>
                <div class="no-events-div">
                  {/* <img src={noEventsImage} alt="No events" style={{ width: '100%' }} /> */}
                  <NoEvents className="no-events-svg" />
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div className="pagination">
          {currentPage > 1 && (
            <button className="pagbtn prev-btn" onClick={() => paginate(currentPage - 1)}>Previous</button>
          )}
          {currentEvents.length > 0 && currentPage < Math.ceil(filteredInfo.length / eventsPerPage) && (
            <button className="pagbtn next-btn" onClick={() => paginate(currentPage + 1)}>Next</button>
          )}
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default Events;
